import React from 'react';
// Joy UI
import Grid from '@mui/material/Grid';
import Card from '@mui/joy/Card';
import Typography from '@mui/joy/Typography';
//
import {
  XS_CARD_WIDTH,
  MD_CARD_WIDTH,
  LG_CARD_WIDTH,
} from '../../utils/constants';

function User({ user }) {
  const {
    displayName,
    email,
    emailVerified,
    customClaims,
    photoURL,
    providerData,
    ittUser,
    metadata,
  } = user;

  // create an email status
  const emailStatus = emailVerified ? 'Verified' : 'Not Verified';
  // get the provider data
  let providerIds = '';
  let authProviderData = {};
  if (providerData && providerData.length > 0) {
    providerIds = providerData
      .map((provider) => provider.providerId)
      .join(', ');
    authProviderData = providerData[0];
  }
  // console.log(`${email} : ${isGlobalAdmin}`);
  const [open, setOpen] = React.useState(false);

  const handleOpenModal = React.useCallback(() => setOpen(true), []);
  const handleCloseModal = React.useCallback(() => setOpen(false), []);

  // const handleCloseModel = React.useCallback((e) => displayCardDetails(e));

  return (
    <>
      <Card
        row
        variant="outlined"
        sx={{
          minWidth: {
            xs: XS_CARD_WIDTH,
            md: MD_CARD_WIDTH,
            lg: LG_CARD_WIDTH,
          },
          width: {
            xs: XS_CARD_WIDTH,
            md: MD_CARD_WIDTH,
            lg: LG_CARD_WIDTH,
          },
          margin: '4px',
        }}
      >
        <Grid container spacing={2}>
          <Grid item xs={4}>
            <Typography level="h5">Firebase</Typography>
            {/* Name */}
            <Typography level="body2">
              {displayName} {!displayName && '-'}
            </Typography>
            {/* Email */}
            <Typography level="body2">
              {email && `${email} (${emailStatus})`} {!email && '-'}
            </Typography>
            {photoURL && <img src={photoURL} alt="user" />}
          </Grid>
          <Grid item xs={4}>
            <Typography level="h5">Provider Data</Typography>
            <Typography level="body2">
              {authProviderData?.displayName}
              {!authProviderData.displayName && '-'}
            </Typography>
            <Typography level="body2">
              {authProviderData?.email}
              {!authProviderData.email && '-'}
            </Typography>
            {authProviderData.photoURL && (
              <img src={authProviderData.photoURL} alt="user" />
            )}
            <Typography level="h6">{providerIds}</Typography>
          </Grid>
          <Grid item xs={4}>
            <Typography level="h5">ITT User Data</Typography>
            <Typography level="body2">
              {ittUser?.displayName}
              {ittUser && !ittUser.displayName && '-'}
            </Typography>
            <Typography level="body2">
              {ittUser?.email}
              {ittUser && !ittUser.email && '-'}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <Typography level="h5">Metadata</Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography level="body2">
              Claims: {JSON.stringify(customClaims, null, 1)}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography level="body2">
              Created: {metadata?.creationTime}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography level="body2">
              Refreshed: {metadata?.lastRefreshTime}
            </Typography>
          </Grid>
          <Grid item xs={3}>
            <Typography level="body2">
              Logged In: {metadata?.lastSignInTime}
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <button onClick={handleOpenModal}>Edit</button>
          </Grid>
        </Grid>
      </Card>
      {open && (
        <div className="modal">
          <div className="modal-content">
            <span
              className="close"
              aria-hidden
              onClick={handleCloseModal}
              role="button"
            >
              &times;
            </span>
            <p>Edit User Details</p>
            {/* Add form or other content here */}
          </div>
        </div>
      )}
    </>
  );
}

export default User;
